:root {
  --colorPrimaryLighter: #ffc7c7;
  --colorPrimaryLight: #f89a9a;
  --colorPrimary: #a80100;
  --colorFocusPrimary: #da550033;
  --colorPrimaryDark: #860707;
  --colorPrimaryDarker: #680303;
  --colorAccentLighter: #ffe199;
  --colorAccentLight: #fac66b;
  --colorAccent: #f79746;
  --colorFocusAccent: #f7974633;
  --colorAccentDark: #dd7c28;
  --colorAccentDarker: #ce650b;
  --colorNegativeLighter: #ffd2d8;
  --colorNegativeLight: #f6bcc3;
  --colorNegative: #d0021b;
  --colorFocusNegative: #d0021b33;
  --colorNegativeDark: #b50016;
  --colorNegativeDarker: #960012;
  --colorWarningLighter: #fdf4d0;
  --colorWarningLight: #fff0b3;
  --colorWarning100: #ffe380;
  --colorWarning: #ffc400;
  --colorFocusWarning: #ffc40033;
  --colorWarning300: #ffab00;
  --colorWarningDark: #ff991f;
  --colorPositiveLighter: #d7f9e7;
  --colorPositiveLight: #aceccb;
  --colorPositive: #22a861;
  --colorFocusPositive: #22a86133;
  --colorPositiveDark: #019044;
  --colorPositiveDarker: #017a3a;
  --colorInformationLighter: #e7ecfc;
  --colorInformationLight: #d7defa;
  --colorInformation: #375de7;
  --colorFocusInformation: #375de733;
  --colorInformationDark: #2c4ab8;
  --colorInformationDarker: #21388b;
  --fontFamily: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --fontRegular: 400;
  --fontMedium: 600;
  --fontBold: 700;
  --radiusSmall: 2px;
  --radiusMedium: 4px;
  --radiusLarge: 8px;
  --radiusExtraLarge: 16px;
}

.Dropdown-container {
  box-shadow: 0 4px 16px rgb(0 0 0 / 16%);
}

.all-text200 * {
  font-size: 14px;
  line-height: 1.43;
}

.all-imageMaxWidthFull img {
  max-width: 100%;
}

.Modal--container .Modal-dialog {
  max-width: 960px;
}

.Table {
  margin-bottom: 0;
}

tr td {
  background-color: inherit;
}

.Table--striped tbody tr:nth-of-type(even) {
  background-color: white;
}

.u-backgroundInherit {
  background-color: inherit;
}

.PageLayout-bodyInner {
  width: 100vw;
}

@media (min-width: 992px) {
  .PageLayout-bodyInner {
    width: 0;
  }
}

body.ModalOpen .Tooltip {
  z-index: 1051;
}

.Button.u-backgroundTransparent {
  background-color: transparent;
}

.Button.u-borderTransparent {
  border-color: transparent;
}

/* Legacy Online Form */
.form-doc-panel {
  font-size: 15px;
  overflow: hidden;
  padding-bottom: 100px;
}
.form-doc-panel * {
  font-family: "Times New Roman", Times, serif !important;
}
.form-doc-panel-portrait {
  width: 790px;
}
.form-doc-panel-portrait .form-doc-panel-content {
  max-width: 720px;
  margin: 40px auto;
  display: block;
}
.form-doc-panel-landscape {
  width: 1090px;
  margin: 0px auto;
}
.form-doc-panel-landscape .form-doc-panel-content {
  max-width: 80%;
  display: block;
  margin: 60px auto;
}
.form-doc-panel-content {
  font-size: 1.1em;
}
.form-doc-panel-content .underline {
  border-bottom: 1px solid black;
  width: 50%;
  margin: 3px auto;
}
.form-doc-panel-content > div {
  margin: 0 0 5px !important;
  overflow: hidden;
}
/* .form-doc-panel-content p>* {
	float: left;
} */
.form-doc-panel-content input {
  height: 25px !important;
}
.form-doc-panel-content span {
  display: inline-block;
}
.form-doc-panel-content h3,
.form-doc-panel-content h4,
.form-doc-panel-content h5,
.form-doc-panel-content h6 {
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 0px;
}
.OOB {
  font-size: 12pt;
  font-weight: bold;
  margin-bottom: 0;
}
.APB {
  font-size: 12pt;
  margin-bottom: 0;
}
.POB {
  font-size: 13pt;
  font-weight: bold;
}
.NOB {
  font-style: italic;
}
.LOB {
  font-size: 13pt;
}
.MOB {
  font-size: 13pt;
  font-weight: bold;
}
